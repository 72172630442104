








































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { BasicsClass } from "@/common/BasicsClass";

@Component
export default class GoverScreenAntiepidemic extends Vue {

	@Prop() curentAdcode: number;

	private form: any = {
		defense: '0',
		id: ''
	};

	private temp = [
		{
			farmCount: 0,
			markCount: 0,
			type: '牛',
			vaccineCardCount: 0,
			vaccineCount: 0,
		}
	];
	
	private needScale: boolean = false;

	created() {
		this.vaccineData(0);
		this.setScale();
	}

	setfontSizeScale(content) {
		return `<span class="${this.needScale ? 'need-scale' : ''}">${content}</span>`
	}
	
	setScale() {
		let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		if (clientWidth === 1280) {
			// console.log('我这边是1920的150%缩放比例，因此需要设置scale');
			this.needScale = true;
		}
	}

	private handleDefense(type: string): void {
		this.vaccineData(this.groupCode);
		this.form.defense = type;
	}

	//定义地址id
	private groupCode:any = 0

	//请求接口数据
	private vaccineData(groupCode:any){
		new BasicsClass().vaccineData("/animal/web/bigScreenGovernmentNew/vaccineData",
		{
			groupCode
		},false,true,(res: any) => {
			// console.log(res);
			let temp = res.data.filter(item => item.type === '牛 ');

			// console.log('过滤后我需要的牛数据', temp);
			this.temp = temp;
			// console.log(this.temp);
			if (!this.temp.length) {
				// console.log('没有数据，要拼接一下');
				this.temp.push({
					farmCount: 0,
					markCount: 0,
					type: '牛',
					vaccineCardCount: 0,
					vaccineCount: 0,
				})
			}
			console.log(this.temp,'this.temp');
			this.$emit('dataFromChild', { Counttemp: this.temp });
		});
	}

	//监视地址id 变化请求数据
	@Watch('curentAdcode')
	private changeAddressId(newVal:any,oldVal:any){
		// console.log('-----变换了地址 春防秋防', newVal,oldVal)
		if(newVal != oldVal){
			this.groupCode = newVal;
			this.vaccineData(newVal);
		}
	}
}
